<template>
  <div>
    <v-sheet
      :color="colorResultadoChecklist"
      elevation="1"
      rounded
      width="100%"
      dark
    >
      <div
        class="subtitle-1 text-center py-1"
      >
        Checklist "{{ title }}"
      </div>
      <div
        class="title text-center py-2"
      >
        <template
          v-if="porcentajeProgreso !== 100"
        >
          No completado ({{ porcentajeProgreso }}% progreso)<br>
        </template>
        <template
          v-if="anomalias.length > 0"
        >
          <span
            v-for="(anomalia, index) in anomalias"
            :key="index"
          >
            {{ anomalia.count }} {{ 'anomalía'|pluralize(anomalia.count, 's') }} "{{ anomalia.grado_anomalia.descripcion }}"<br>
          </span>
        </template>
        <template
          v-else-if="countPreguntasDesconocidas > 0"
        >
          Hay preguntas desconocidas<br>
        </template>
        <template
          v-else
        >
          Sin anomalías detectadas<br>
        </template>
        <template
          v-if="countAccionesCorrectoras > 0"
        >
          <span>
            {{ countAccionesCorrectoras }} {{ 'acci'|pluralize(countAccionesCorrectoras, 'ones correctoras', 'ón correctora') }}
          </span>
        </template>
      </div>
      <v-btn
        v-if="anomalias.length > 0"
        block
        text
        @click.stop="clickAnomalias"
      >
        <v-icon left>
          {{ $vuetify.icons.values.info }}
        </v-icon>Ver anomalias
      </v-btn>
      <v-btn
        v-if="countAccionesCorrectoras > 0"
        block
        text
        @click.stop="clickAccionesCorrectoras"
      >
        <v-icon left>
          {{ $vuetify.icons.values.info }}
        </v-icon>Ver acciones correctoras
      </v-btn>
    </v-sheet>
  </div>
</template>

<script>
import { GRUPO_CHECKLIST } from '@/utils/consts'

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    anomalias: {
      type: Array,
      default: () => [],
    },
    countAccionesCorrectoras: {
      type: Number,
      default: 0,
    },
    countPreguntasDesconocidas: {
      type: Number,
      default: 0,
    },
    resultadoChecklist: {
      type: Number,
      default: 0,
    },
    porcentajeProgreso: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    colorResultadoChecklist () {
      if (this.resultadoChecklist === GRUPO_CHECKLIST.resultado.ok) {
        return GRUPO_CHECKLIST.colorResultado.ok
      } else if (this.resultadoChecklist === GRUPO_CHECKLIST.resultado.conAnomalias) {
        return GRUPO_CHECKLIST.colorResultado.conAnomalias
      } else {
        return GRUPO_CHECKLIST.colorResultado.sinContestar
      }
    },
  },
  methods: {
    clickAnomalias () {
      this.$emit('click-anomalias')
    },
    clickAccionesCorrectoras () {
      this.$emit('click-acciones-correctoras')
    },
  },
}
</script>