export default {
  _selectCountPreguntasBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        Vue.$offline.db.fn.count(
          tables.pregunta_checklist_ot.idpregunta_checklist_ot
        ).as('cant_preguntas')
      )
      .from(tables.grupo_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(tables.pregunta_checklist_ot.idgrupo_checklist_ot)
      )
  },
  _selectCountPreguntasNoContestadas (Vue, idchecklistOt) {
    const tables = Vue.$offline.db.tables
    return this._selectCountPreguntasBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.pregunta_checklist_ot.idgrado_anomalia.isNull()
        )
      )
      .exec()
  },
  _selectCountPreguntasContestadas (Vue, idchecklistOt) {
    const tables = Vue.$offline.db.tables
    return this._selectCountPreguntasBase(Vue)
      .where(
        Vue.$offline.db.op.and(
          tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
          tables.pregunta_checklist_ot.idgrado_anomalia.isNotNull()
        )
      )
      .exec()
  },
  async _addSubqueriesParteTrabajo (Vue, rows) {
    for (let row of rows) {
      row.emails = await Vue.$offline.sistemaTelefono.selectConSAT(row.sistema.idsistema)
      row.direccion = await Vue.$offline.clienteDireccion.formatearDireccion(row.cliente_direccion)
      if (row.checklist_ot.idchecklist_ot) {
        // preguntas
        const contestadas = await this._selectCountPreguntasContestadas(
          Vue, row.checklist_ot.idchecklist_ot
        )
        const preguntasContestadas = contestadas[0].cant_preguntas
        const noContestadas = await this._selectCountPreguntasNoContestadas(
          Vue, row.checklist_ot.idchecklist_ot
        )
        const preguntasNoContestadas = noContestadas[0].cant_preguntas
        if (preguntasNoContestadas + preguntasContestadas > 0) {
          row.porcentajeProgreso = Math.round(
            preguntasContestadas * 100 / (preguntasNoContestadas + preguntasContestadas)
          )
        } else {
          row.porcentajeProgreso = 100
        }
        // resultado checklist
        const resultadoChecklist = await Vue.$offline.checklistOt.resultado(
          row.checklist_ot.idchecklist_ot
        )
        row.resultadoChecklist = resultadoChecklist
        // anomalias
        row.anomalias = await Vue.$offline.parteTrabajo.selectAnomaliasResumen(
          row.parte_trabajo.idparte_trabajo, row.checklist_ot.idchecklist_ot
        )
        // acciones correctoras
        row.accionesCorrectoras = await Vue.$offline.parteTrabajo.selectAccionesCorrectorasResumen(
          row.parte_trabajo.idparte_trabajo, row.checklist_ot.idchecklist_ot
        )
        // preguntas desconocidas
        row.countPreguntasDesconocidas = (await Vue.$offline.checklistOt.selectDesconocidas(
          row.checklist_ot.idchecklist_ot
        )).length
      }
    }
    return rows
  },
  async selectParteTrabajo (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    const rows = await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo)
      .innerJoin(
        tables.orden_trabajo,
        tables.parte_trabajo.idorden_trabajo.eq(tables.orden_trabajo.idorden_trabajo)
      )
      .innerJoin(
        tables.sistema,
        tables.orden_trabajo.idsistema.eq(tables.sistema.idsistema)
      )
      .innerJoin(
        tables.cliente,
        tables.sistema.idcliente.eq(tables.cliente.idcliente)
      )
      .innerJoin(
        tables.regimen_fiscal,
        tables.cliente.idregimen_fiscal.eq(tables.regimen_fiscal.idregimen_fiscal)
      )
      .leftOuterJoin(
        tables.cliente_direccion,
        tables.sistema.idcliente_direccion_sist.eq(tables.cliente_direccion.idcliente_direccion)
      )
      .innerJoin(
        tables.tsistema,
        tables.sistema.idtsistema.eq(tables.tsistema.idtsistema)
      )
      .innerJoin(
        tables.tactuacion_tsistema,
        tables.orden_trabajo.idtactuacion_tsistema.eq(tables.tactuacion_tsistema.idtactuacion_tsistema)
      )
      .innerJoin(
        tables.tactuacion,
        tables.tactuacion_tsistema.idtactuacion.eq(tables.tactuacion.idtactuacion)
      )
      .leftOuterJoin(
        tables.checklist_ot,
        tables.orden_trabajo.idorden_trabajo.eq(tables.checklist_ot.idorden_trabajo)
      )
      .leftOuterJoin(
        tables.nivel_satisfaccion,
        tables.orden_trabajo.idnivel_satisfaccion.eq(tables.nivel_satisfaccion.idnivel_satisfaccion)
      )
      .where(tables.parte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .exec()
    return (await this._addSubqueriesParteTrabajo(Vue, rows))[0]
  },
  selectLparteTrabajo (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select(
        tables.subsis.idsubsis,
        tables.subsis.descripcion,
        tables.lparte_trabajo.idarticulo,
        tables.lparte_trabajo.descripcion,
        tables.lparte_trabajo.codigo,
        tables.lparte_trabajo.facturar,
        tables.lorden_trabajo.dto,
        tables.lorden_trabajo.precio,
        tables.lorden_trabajo.iva,
        tables.lorden_trabajo.recargo,
        Vue.$offline.db.fn.sum(
          tables.lparte_trabajo.unidades
        ).as('unidades')
      )
      .from(tables.lparte_trabajo)
      .innerJoin(
        tables.lorden_trabajo,
        tables.lparte_trabajo.idlorden_trabajo.eq(tables.lorden_trabajo.idlorden_trabajo)
      )
      .innerJoin(
        tables.subsis,
        tables.lparte_trabajo.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(tables.lparte_trabajo.idparte_trabajo.eq(idparteTrabajo))
      .groupBy(
        tables.subsis.idsubsis,
        tables.subsis.descripcion,
        tables.lparte_trabajo.idarticulo,
        tables.lparte_trabajo.descripcion,
        tables.lparte_trabajo.codigo,
        tables.lparte_trabajo.facturar,
        tables.lorden_trabajo.dto,
        tables.lorden_trabajo.precio,
        tables.lorden_trabajo.iva,
        tables.lorden_trabajo.recargo
      )
      .orderBy(tables.subsis.orden)
      .orderBy(tables.lparte_trabajo.idlparte_trabajo)
      .exec()
  },
  _addSubqueriesTiempoTrabajado (Vue, rows) {
    for (let row of rows) {
      row.tiempoTrabajado = row.tiempo_trabajado.ffin.getTime() - row.tiempo_trabajado.finicio.getTime()
    }
    return rows
  },
  async selectTiempoTrabajado (Vue, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    const rows = await Vue.$offline.db
      .select()
      .from(tables.tiempo_trabajado)
      .innerJoin(
        tables.tecnico,
        tables.tiempo_trabajado.idempleado.eq(tables.tecnico.idempleado)
      )
      .innerJoin(
        tables.empleado,
        tables.tiempo_trabajado.idempleado.eq(tables.empleado.idempleado)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.tiempo_trabajado.estado.gt(0),
          tables.tiempo_trabajado.idparte_trabajo.eq(idparteTrabajo),
        )
      )
      .orderBy(tables.tiempo_trabajado.finicio, Vue.$offline.db.order.ASC)
      .exec()
    return await this._addSubqueriesTiempoTrabajado(Vue, rows)
  },
}
