<template>
  <div>
    <v-sheet
      color="info"
      elevation="1"
      rounded
      width="100%"
      dark
    >
      <div
        class="subtitle-1 text-center py-1"
      >
        Intervención
      </div>
      <div
        class="text-h4 text-center py-2"
      >
        <template
          v-if="mostrarImporte"
        >
          <template
            v-if="totalCobrado === 0"
          >
            {{ totales.total|currency }}
            <span class="subtitle-2">({{ totales.bi_dto|currency }} IVA no incl.)</span>
          </template>
          <v-simple-table
            v-else
            class="info mx-5"
            dense
          >
            <tbody>
              <tr>
                <td
                  class="text-left title"
                >
                  Total
                </td>
                <td
                  class="text-right title"
                >
                  {{ totales.total|currency }}
                  <br><small>({{ totales.bi_dto|currency }} IVA no incl.)</small>
                </td>
              </tr>
              <tr
                v-if="totalCobrado > 0"
              >
                <td
                  class="text-left"
                >
                  Cobrado
                </td>
                <td
                  class="text-right"
                >
                  {{ totalCobrado|currency }}
                </td>
              </tr>
              <tr
                v-if="totalCobrado > 0"
              >
                <td
                  class="text-left"
                >
                  Pendiente
                </td>
                <td
                  class="text-right"
                >
                  {{ pendienteCobrar|currency }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </template>
        <template
          v-else
        >
          {{ totales.unidades }} {{ 'unidad'|pluralize(totales.unidades, 'es') }}
          <span class="subtitle-2">(a instalar/facturar)</span>
        </template>
      </div>
      <v-btn
        v-if="totales.bi_dto > 0"
        block
        text
        @click.stop="clickDesglose"
      >
        <v-icon
          left
        >
          {{ $vuetify.icons.values.info }}
        </v-icon>Ver detallado
      </v-btn>
      <v-btn
        v-if="totales.bi_dto > 0 && mostrarImporte && pendienteCobrar > 0 && hasPermCobrarIntervencion"
        block
        text
        @click.stop="clickCobrar"
      >
        <v-icon
          left
        >
          {{ $vuetify.icons.values.cobrar }}
        </v-icon>Cobrar
      </v-btn>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    totales: {
      type: Object,
      default: () => {},
    },
    totalCobrado: {
      type: Number,
      required: true,
    },
    mostrarImporte: {
      type: Boolean,
      default: false,
    },
    hasPermCobrarIntervencion: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    pendienteCobrar () {
      let pendiente = this.totales.total - this.totalCobrado
      if (pendiente < 0) {
        pendiente = 0
      }
      return pendiente
    },
  },
  methods: {
    clickDesglose () {
      this.$emit('click-desglose')
    },
    clickCobrar () {
      this.$emit('click-cobrar')
    },
  },
}
</script>